import React from "react";

// import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <>
      {/*Header Start*/}
      <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-light">


        <div className="togal-thumb">

        <NavLink aria-current="page" className="navbar-brand" to="/">
          <img
            src="/images/logo.png"
            alt="Lumineers The Clinic Logo"
            className="img-fluid"
            width="250"
          />
        </NavLink>


        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        </div>


        <div className="mbphone">
         <span>Complimentary Consultation</span>
          <a className="phonenum" href="tel:404-781-7978">(404-781-7978)</a>
        </div>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <NavLink className="nav-link" to="/blog">
                Blog
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/what-to-expect">
                What to Expect
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact-us">
                Contact Us
              </NavLink>
            </li>
            <li className="nav-item">
              <a className="nav-link phonenum" href="tel:404-781-7978">
                (404-781-7978)
              </a>
            </li>
          </ul>
        </div>
      </nav>
      {/*Header Start*/}
    </>
  );
};
export default Header;
