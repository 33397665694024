import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/*Footer Start*/}

      <div className="sub-footer">
        <div className="container office-info">
          <div className="row align-items-start">
            <div className="col-md">
              <div className="office-hours mt-5 mb-5">
                <h3 className="bold">Office Hours:</h3>
                <p>Monday 9:00am - 5:00pm</p>
                <p>Tuesday 9:00am - 5:00pm</p>
                <p>Wednesday 9:00am - 5:00pm</p>
                <p>Thursday 9:00am - 5:00pm</p>
                <div>
                  <a href="https://www.carecredit.com/go/852FGR/B3CALAdToolkitANCardDental/">
                    <img
                      className="cardImg"
                      src="https://www.carecredit.com/adtoolkit/assets/pages/library/buttons/350x213/CareCredit_Button_ApplyNow_350x213_f_v1.png"
                      alt="care credit"
                    />
                  </a>
                </div>
                <div className="pt-3">
                  <a href="https://www.unitedmedicalcredit.com/start-an-application/?pid=0010y00001pjDJQ">
                    <img
                      src="https://www.unitedmedicalcredit.com/b/s-125125-GREEN.png"
                      alt="united medical credit"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md mt-5 mb-5">
              <div className="location-contact-info">
                <h3 className="bold">Clinic Address</h3>
                <a
                  href="https://maps.app.goo.gl/5yARBekCFLgvCRPh6"
                  target="_blank"
                >
                  <p>
                    720 Church St Decatur, <br />
                    GA 30030
                  </p>
                </a>
                <h3 className="bold">Phone</h3>
                <p className="bold">
                  <span className="phone-number">
                    <a href="tel:404-781-7978">(404-781-7978)</a>
                  </span>
                </p>
                <h3 className="bold">Email</h3>
                <p className="bold">
                  <a href="mailto:veneersmilesatl@gmail.com">
                    veneersmilesatl@gmail.com
                  </a>
                </p>
                <p className="bold">
                  <Link to="/contact-us">Contact Us Online</Link>
                </p>
              </div>
            </div>
            <div className="col-md mt-5 mb-5">
              <img
                src="/images/lumineers-clinic-atlanta-59558607b678889c31c4c48974e8a6ce.jpg"
                className="img-fluid d-block mx-auto"
                alt="Lumineers Clinic Atlanta"
              />
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="container">
          <div className="row align-items-end">
            <div className="col-md">
              <div className="left-footer-container">
                <Link to="/privacy">Privacy Policy </Link> |
                <Link to="/contact-us"> Contact Us</Link> |
                <Link to="/blog"> Blog</Link>
              </div>
            </div>
            <div className="col-md">
              <div className="right-footer-container">
                <div className="address">
                  Veneer Smiles A Lumineers Clinic ©2023
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/*Footer End*/}
    </>
  );
};
export default Footer;
