import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, ButtonToolbar, Modal } from "react-bootstrap";
import axios from "axios";
import swal from "sweetalert";
import { Helmet } from "react-helmet";
import $ from "jquery";
import useDigitInput from "react-digit-input";
import FrontLayout from "../layout/front";
import "./upload.css";
function UpLoad() {
  const [value, setValue] = React.useState("");
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFileUrls, setUploadedFileUrls] = useState([]);
  const [showContent, setShowContent] = useState(true);
  const [show, setShow] = useState(false);

  const [messageHeading, setMessageHeading] = useState('Sorry');
  const [messageBody, setMessageBody] = useState('This link has been already used to post image.');

  const modalRef = useRef(null);
  const [FormData, setFormData] = useState({
    code: "",
    unique_link: "",
    thumbnail: [],
    ref_id: "",
  });
  const [intFormData, setIntFormData] = useState({
    code: "",
    unique_link: "",
    thumbnail: [],
    ref_id: "",
  });

  const { link_code } = useParams();
  const ImageAllowUpload = ["jpeg", "jpg", "png"];

  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 4,
    value,
    onChange: (newValue) => {
      setValue(newValue);
      validateDigits(newValue);
    },
  });

  const validateDigits = (inputDigits) => {
    inputDigits = inputDigits.trim();
    if (inputDigits.length === 4) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  useEffect(() => {
    axios
      .get(
        "https://www.admin.veneersmilesclinic.com/api/verify-link-code/" +
          link_code
      )
      .then((response) => {
        console.log(response.data);

        if (
          response.data.status == 200 &&
          response.data.message == "verified"
        ) {
          setFormData({
            code: response.data.data.code,
            unique_link: response.data.data.link_code,
            thumbnail: [],
            ref_id: response.data.data.id,
          });
        } else {
          setShowContent(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    let allowProcessShow = true;
    let errorMessage = "";
    let imageSize = e.target.files[0]["size"];
    imageSize = (imageSize / (1024 * 1024)).toFixed(0);
    let image_extension = e.target.files[0]["name"];
    image_extension = image_extension.split(".").pop().toLowerCase();
    if (imageSize > 10) {
      allowProcessShow = false;
      errorMessage = `You are only allowed to upload image size 10 MB while selected image is ${imageSize} MB`;
    } else if (!ImageAllowUpload.includes(image_extension)) {
      allowProcessShow = false;
      errorMessage = `Your are only allowed to upload image with jpeg, jpg ,png extension`;
    }

    if (allowProcessShow) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ["thumbnail"]: e.target.files[0], // Replace the array with a new array containing the single selected file
      }));

      const files = Array.from(e.target.files);
      // Update uploaded files state with new files
      setUploadedFiles([...uploadedFiles, ...files]);
      // Generate URLs for new files and update URL state

      setUploadedFiles([file]);

      const urls = [URL.createObjectURL(file)];
      setUploadedFileUrls(urls);
    } else {
      swal({
        title: "Sorry!",
        text: errorMessage,
        icon: "error",
        button: "Ok",
      });
    }
  };
  const handleFileDelete = (index) => {
    // Remove the file object from the files state
    const updatedFiles = uploadedFiles.filter((file, i) => i !== index);
    setUploadedFiles(updatedFiles);
    // Remove the corresponding URL from the URLs state
    const updatedUrls = uploadedFileUrls.filter((url, i) => i !== index);
    setUploadedFileUrls(updatedUrls);
    $("#upload").val("");
    setFormData((pre) => ({
      ...pre,
      ["thumbnail"]: [],
    }));
  };
  const handleVerify = (e) => {
    e.preventDefault();

    if (parseInt(value) === parseInt(FormData.code)) {
      setShow(true);
    } else {
      swal({
        title: "Sorry!",
        text: "Your 4 digit pin not matched",
        icon: "error",
        button: "Ok",
      });
    }
  };
  const UploadFormData = () => {
    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post(
        "https://www.admin.veneersmilesclinic.com/api/store-image-handle",
        FormData,
        axiosConfig
      )
      .then((response) => {
        if (response.data.status == 200) {
          setShow(false);
          setShowContent(false);
          setMessageHeading('Thank You');
          setMessageBody('Your image has been submitted.');
          swal({
            title: "Success!",
            text: response.data.message,
            icon: "success",
            button: "Ok",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const isImageSelected = uploadedFiles.length > 0;
  const getContent = () => {
    return (
      <>
        <Helmet>
          <title>
            Certified Lumineers Dentist Atlanta GA | Low Cost Affordable
            Porcelain Dental Veneers
          </title>
          <meta
            name="description"
            content="The Certified Lumineers Dentists of Veneer Smiles A Lumineers Clinic in Atlanta GA specialize in placing minimally invasive, painless, low-cost affordable porcelain dental veneers."
          ></meta>
        </Helmet>

        {showContent === true ? (
          <div className="upoad-pic">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-holder">
                    <form onSubmit={handleVerify}>
                      <label htmlFor="pic" className="label">
                        Enter 4 Digit PIN:
                      </label>
                      <div className="input-holder">
                        {digits.map((digit, index) => (
                          <React.Fragment key={index}>
                            <input
                              className="form-control"
                              inputMode="decimal"
                              {...digit}
                            />
                            {index < 3 && <span className="hyphen">-</span>}
                          </React.Fragment>
                        ))}
                      </div>

                      {isFormValid === true ? (
                        <button
                          className="btn btn-primary"
                          onClick={handleVerify}
                        >
                          Verify
                        </button>
                      ) : null}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="upoad-pic">
            <div className="page-not-found">
              <h1>{messageHeading}</h1>
              <p>{messageBody}</p>
            </div>
          </div>
        )}

        <Modal
          show={show}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className="modal-body">
            <p>
              Please upload a photo of yourself wearing a cheerful smile, as
              shown in this image.
            </p>
            <div className="img">
              <img
                src="/images/smile-pic.jpg"
                alt="smile pic"
                className="img-fluid m-auto d-block"
              />
            </div>
            <form>
              <label htmlFor="upload" className="upload-file-area">
                <div className="icon">
                  <img
                    src="/images/ico-upload-file.svg"
                    alt="upload file"
                    width={36}
                    height={36}
                  />
                </div>
                <h3>Upload your photo</h3>
                <p>It must be a smile pose</p>
                <input
                  type="file"
                  id="upload"
                  className="file-hidden"
                  onChange={handleFileChange}
                />
              </label>
              <div className="uploaded-area">
                {uploadedFiles.map((file, index) => (
                  <div className="upload" key={index}>
                    <div className="img">
                      <img
                        src={URL.createObjectURL(file)}
                        width={50}
                        height={50}
                        alt=""
                      />
                    </div>
                    <div className="name">
                      <p>{file.name}</p>
                    </div>
                    <button
                      className="close"
                      onClick={() => handleFileDelete(index)}
                    >
                      x
                    </button>
                  </div>
                ))}
              </div>
              <button
                type="button"
                onClick={UploadFormData}
                className={`btn-submit ${!isImageSelected ? "disabled" : ""}`}
                disabled={!isImageSelected}
              >
                Submit
              </button>
            </form>
          </div>
        </Modal>
      </>
    );
  };
  let view = getContent();
  return <FrontLayout view={view} />;
}
export default UpLoad;
