import { Helmet } from "react-helmet";
import React from "react";
import { useState,useEffect } from "react";

import FrontLayout from "../layout/front";
import $ from "jquery";
import swal from "sweetalert";
import axios from "axios";
import { isEmail, validatePhone } from "./ValidFormFunction";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUs = () => {
  const recaptchaRef = React.createRef();

  const [seoData, setSeoData] = useState([]);

  const apiSeoUrl = "https://www.admin.veneersmilesclinic.com/api/get-seo-data";

  const fetchSeoData = async () => {
    try {
      const response = await axios.get(`${apiSeoUrl}/283`);
    	setSeoData(response.data.seo_data);
    } catch (error) {
      console.error('Error fetching blog detail:', error);
    }
  };

	// Call fetchData on component mount
	useEffect(() => {
		fetchSeoData();
	}, []);

  const [input, setInput] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    message: "",
  });

  const [errorForm, setErrorForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    message: "",
  });

  const [intError, setIntError] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    message: "",
  });

  const setErrorEmpty = () => {
    setErrorForm((pre) => ({
      ...pre,
      ...intError,
    }));
  };

  const InputChangeHandle = (e) => {
    setInput((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
  };

  $(".phone").on("keydown", function (e) {
    var ssnval = $(this).val();

    var Len = ssnval.length;

    if (e.keyCode > 47 && e.keyCode < 58) {
    } else if (e.keyCode > 95 && e.keyCode < 106) {
    } else if (e.keyCode === 9) {
      return true;
    } else if (e.keyCode === 8 || e.keyCode === 13 || e.keyCode === 173) {
    } else {
      return false;
    }

    if (e.keyCode !== 8) {
      if (e.keyCode !== 173) {
        if (Len === 3 || Len === 7) {
          $(this).val($(this).val() + "-");
        }

        if (Len >= 12) {
          return false;
        }
      }
    }
  });

  const FormSubmit = () => {
    setErrorEmpty();

    let validCheck = true;
    if (!isEmail(input.email) || input.email === "") {
      setErrorForm((pre) => ({
        ...pre,
        ["email"]: "Email must be valid",
      }));
      validCheck = false;
    }

    if (!validatePhone(input.phone) || input.phone === " ") {
      setErrorForm((pre) => ({
        ...pre,
        ["phone"]: "* Phone must be valid (format: xxx-xxx-xxxx)",
      }));
      validCheck = false;
    }

    if (input.first_name.length < 3) {
      setErrorForm((pre) => ({
        ...pre,
        ["first_name"]: "* First Name is required",
      }));
      validCheck = false;
    }

    if (input.last_name.length < 3) {
      setErrorForm((pre) => ({
        ...pre,
        ["last_name"]: "* Last Name is required",
      }));
      validCheck = false;
    }

    if (input.address.length < 3) {
      setErrorForm((pre) => ({
        ...pre,
        ["address"]: "* Address is required",
      }));
      validCheck = false;
    }

    if (input.message.length < 3) {
      setErrorForm((pre) => ({
        ...pre,
        ["message"]: "* Message is required",
      }));
      validCheck = false;
    }

    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue.length === 0) {
      validCheck = false;
      swal({
        title: "Sorry!",
        text: "Please Select I Am Not Rebot",
        icon: "error",
        button: "Ok",
      });
    }

    if (validCheck) {
      const new_data = {
        recaptchaValue: recaptchaValue,
        first_name: input.first_name,
        last_name: input.last_name,
        email: input.email,
        phone: input.phone,
        address: input.address,
        message: input.message,
      };
      axios
        .post(
          "https://www.admin.veneersmilesclinic.com/api/contact-us-request",
          new_data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then(function (response) {
          if (response.data.status == 200) {
            setErrorForm((...pre) => ({
              ...pre,
              ...intError,
            }));

            setInput((...pre) => ({
              ...pre,
              ...intError,
            }));

            swal({
              title: "Thank You!",
              text: response.data.message,
              icon: "success",
              button: "Ok",
            });
          } else {
            swal({
              title: "Sorry!",
              text: response.data.message,
              icon: "error",
              button: "Ok",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const getContent = () => {
    return (
      <>
        <Helmet>
					<title>
						{seoData.meta_title}
					</title>
					<meta
						name="description"
						content={seoData.meta_description}
					></meta>

					<meta
						name="keywords"
						content={seoData.meta_keywords}
					></meta>
					<link rel="canonical" href={seoData.canonical_url}></link>
				</Helmet>
        <main>
          <div className="contact-one__form-box text-center">
            <div className="container">
              <h3 className="ctheading">Feel Free to Write us Anytime</h3>
              <form
                action=""
                method="#"
                className="contact-one__form contact-form-validated"
                id="validatethis"
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="contact-one__input-box">
                      <input
                        type="text"
                        placeholder="First Name"
                        name="first_name"
                        msg="First Name"
                        className="valid"
                        value={input.first_name}
                        onChange={InputChangeHandle}
                      />
                      <span className="error invalid-feedback">
                        {errorForm.first_name}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="contact-one__input-box">
                      <input
                        type="text"
                        placeholder="Last Name"
                        name="last_name"
                        msg="Last Name"
                        className="valid"
                        value={input.last_name}
                        onChange={InputChangeHandle}
                      />

                      <span className="error invalid-feedback">
                        {errorForm.last_name}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="contact-one__input-box">
                      <input
                        type="email"
                        placeholder="Email Address"
                        name="email"
                        msg="Email"
                        className="email"
                        value={input.email}
                        onChange={InputChangeHandle}
                      />
                      <span className="error invalid-feedback">
                        {errorForm.email}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="contact-one__input-box">
                      <input
                        type="text"
                        placeholder="Phone"
                        name="phone"
                        msg="Phone"
                        className="phone"
                        value={input.phone}
                        onChange={InputChangeHandle}
                      />
                      <span className="error invalid-feedback">
                        {errorForm.phone}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="contact-one__input-box">
                      <input
                        type="text"
                        placeholder="Address"
                        name="address"
                        msg="Address"
                        className="valid"
                        value={input.address}
                        onChange={InputChangeHandle}
                      />
                      <span className="error invalid-feedback">
                        {errorForm.address}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="contact-one__input-box text-message-box">
                      <textarea
                        name="message"
                        placeholder="Message"
                        msg="Message"
                        className="valid"
                        value={input.message}
                        onChange={InputChangeHandle}
                      >
                        {input.message}
                      </textarea>

                      <span className="error invalid-feedback">
                        {errorForm.message}
                      </span>
                    </div>

                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Ld3K20oAAAAABcjnAq5O2pH9O4wO7P79Oy73K6X"
                    />

                    <div className="contact-one__btn-box mt-3">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={FormSubmit}
                      >
                        <span className="eduact-btn__curve"></span>Send a
                        Message<i className="icon-arrow"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </main>
      </>
    );
  };

  let view = getContent();

  return <FrontLayout view={view} />;
};
export default ContactUs;
