import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import FrontLayout from "../layout/front";
import Loader from "../loader/Loader";
import '../blogListing/blogListing.css';

function BlogDetail() {
	// Base URL
	const baseBlogDetail = "https://www.admin.veneersmilesclinic.com/api/get-blog-detail";
	const baseURL = 'https://www.admin.veneersmilesclinic.com/api/get-all-blogs';
	const blogImgURL = "https://www.admin.veneersmilesclinic.com/storage/uploads/blog";
	const backupImageUrl = 'https://placehold.co/600x400';

	const [blogData, setBlogData] = useState([]);
	const [blogCatData, setBlogCatData] = useState([]);
	const [statusCode, setStatusCode] = useState(null);
	const [showContent, setShowContent] = useState(true);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [activeCategory, setActiveCategory] = useState(null);
	const { slug } = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	

	useEffect(() => {
		const fetchBlogDetail = async () => {
			try {
				const response = await axios.get(`${baseBlogDetail}/${slug}`);
				setBlogData(response.data.blog_details);
				setStatusCode(response.status);
				setActiveCategory(response.data.blog_details.cate_ids);
				setShowContent(true);
			} catch (error) {
				console.error('Error fetching blog detail:', error);
				setShowContent(false);
			}
		};

		const fetchCategories = async () => {
			try {
				const response = await axios.get(baseURL);
				setBlogCatData(response.data.blog_category);
			} catch (error) {
				console.error('Error fetching blog categories:', error);
			}
		};

		fetchBlogDetail();
		fetchCategories();
	}, []);

	const handleCategorySelect = (categoryID) => {
		window.sessionStorage.setItem('cat_active_id', categoryID);
		navigate('/blog?category='+categoryID);
	};

	const getContent = () => {

		return (
      <>
        {showContent === true ? (
          <Helmet>
            <title>{blogData.meta_title}</title>
            <meta name="description" content={blogData.meta_description}></meta>

            <meta name="keywords" content={blogData.meta_keywords}></meta>
            <link rel="canonical" href={blogData.canonical_url}></link>
          </Helmet>
        ) : (
          ""
        )}
        <div className="blog-area">
          <>
            <div className="blog-heading">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    {showContent === false ? (
                      <strong className="page-title">
                        404 - Blog Not Found
                      </strong>
                    ) : (
                      showContent && (
                        <strong className="page-title">Blog Detail</strong>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="blog-listing">
              {showContent === true ? (
                <div className="container">
                  {blogData ? (
                    <>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="post thumbnail">
                            <div className="img">
                              <img
                                src={`${blogImgURL}/${blogData.featured_img}`}
                                alt={`${blogData.featured_img_alt}`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="widget sticky">
                            <strong className="title">Categories</strong>
                            <ul>
                              {blogCatData.map((category, index) => (
                                <li
                                  key={index}
                                  className={
                                    activeCategory === category.ID.toString()
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() =>
                                    handleCategorySelect(category.ID)
                                  }
                                >
                                  <Link>{category.cate_title}</Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="post detail">
                                <div className="text">
                                  <h1>
                                    <strong>{blogData.title}</strong>
                                  </h1>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: blogData.description,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        </div>
      </>
    );
	}

	let view = getContent();
	return <FrontLayout view={view} />;
}
export default BlogDetail