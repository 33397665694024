import { Helmet } from "react-helmet";
import FrontLayout from "../layout/front";
import { useState,useEffect } from "react";
import axios from "axios";

const Expect = () => {

  const [seoData, setSeoData] = useState([]);

  const apiSeoUrl = "https://www.admin.veneersmilesclinic.com/api/get-seo-data";

  const fetchSeoData = async () => {
    try {
      const response = await axios.get(`${apiSeoUrl}/284`);
    	setSeoData(response.data.seo_data);
    } catch (error) {
      console.error('Error fetching blog detail:', error);
    }
  };

	// Call fetchData on component mount
	useEffect(() => {
		fetchSeoData();
	}, []);
  const getContent = () => {
    return (
      <>
        <Helmet>
					<title>
						{seoData.meta_title}
					</title>
					<meta
						name="description"
						content={seoData.meta_description}
					></meta>

					<meta
						name="keywords"
						content={seoData.meta_keywords}
					></meta>
					<link rel="canonical" href={seoData.canonical_url}></link>
				</Helmet>
        <main>
          <div className="wte-container mb-5">
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <h1>What to Expect</h1>
                  <p>
                    Lumineers <sup>®</sup> are digitally designed to be
                    ultra-thin (about as thin as a contact lens), and can be
                    applied seamlessly over your teeth to deliver a natural
                    looking smile. The minimally invasive Lumineers placement
                    procedure typically eliminates the need for shots or
                    temporary restorations, providing a comfortable and painless
                    experience.
                  </p>
                  <div className="ratio ratio-16x9 mb-5">
                    <iframe
                      src="https://www.youtube.com/embed/xbx3ZTwlCYM"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      title="Lumineers Alanta"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container dentist-info">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <h2 className="text-center">
                  What is a Certified Lumineers Dentist?
                </h2>
                <p className="mb-5">
                  While many dentists offer traditional veneers, our Certified
                  Lumineers Dentists have been specifically trained to place
                  ultra-thin, minimally invasive Lumineers. Choose a cosmetic
                  dentist that specializes in this unique painless technique and
                  is intricately familiar with the materials involved. Lumineers
                  are proven to last up to 20 years when placed by experienced
                  Certified Lumineers Dentists.
                </p>
              </div>
            </div>
          </div>
          <div className="container steps-container">
            <div className="row">
              <div className="col-md">
                <div className="mt-5 mb-5">
                  <div className="steps-title">
                    <h2>How Does It Work?</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-md">
                <img
                  src="images/lumineers-consultation-718f7843c14ca32571c234064f2c7b42.jpg"
                  alt="Step 1"
                  className="img-fluid d-block mx-auto rounded-circle"
                />
                <h3 className="text-center mt-2">Step 1</h3>
                <p className="bold text-center">Consultation</p>
                <p>
                  During the initial consultation your Certified Lumineers
                  Dentist will determine if you are a candidate for minimally
                  invasive veneers. The dentist will discuss options and preview
                  what your final smile may look like using our LumiSmile design
                  application.
                </p>
              </div>
              <div className="col-md">
                <img
                  src="images/lumineers-impression-e993ea81d09e9d68eeebdaa489bbbec1.jpg"
                  alt="Step 2"
                  className="img-fluid d-block mx-auto rounded-circle"
                />
                <h3 className="text-center mt-2">Step 2</h3>
                <p className="bold text-center">Impressions</p>
                <p>
                  An impression of your teeth are taken and sent to a laboratory
                  that specializes in Lumineers manufacturing. Lumineers are
                  only manufactured by DenMat Laboratory in California, USA,
                  ensuring that the materials used are safe and crafted by
                  master technicians.
                </p>
              </div>
              <div className="col-md">
                <img
                  src="images/lumineers-placement-c0095f1a55f4d935afc662823b1f9411.jpg"
                  alt="Step 3"
                  className="img-fluid d-block mx-auto rounded-circle"
                />
                <h3 className="text-center mt-2">Step 3</h3>
                <p className="bold text-center">Veneers</p>
                <p>
                  On your next visit, your Lumineers will be placed. Lumineers
                  are ultra-thin and in most cases you will get a perfect fit
                  without shots or grinding down of the original tooth
                  structure. The procedure is typically pain-free and there is
                  rarely any post placement sensitivity.
                </p>
              </div>
            </div>
          </div>
          <div className="container before-after-container">
            <div className="row before-after">
              <div className="col-md-8 offset-md-2">
                <h2 className="text-center">Still Not Convinced?</h2>
                <p className="">
                  Our Certified Lumineers Dentists have collectively placed
                  hundreds of Lumineers and have many years of experience using
                  the minimally invasive techniques involved with Lumineers
                  placement. Patients report more confidence, increases in
                  self-esteem and simply smile more often than prior to the
                  procedure.
                </p>
                <div className="before-after-img-container mt-5 p-2">
                  <h3 className="text-center">Before &amp; Afters</h3>
                  <img
                    src="images/adam-lumineers-2b307cc59d043f0307703bde86a6b47e.jpg"
                    alt="Adam Before and After Lumineers"
                    className="img-fluid d-block mx-auto mb-5"
                  />
                  <img
                    src="images/madison-lumineers-564431ae89b630131ca0a0775732e0a7.jpg"
                    alt="Madison Before and After Lumineers"
                    className="img-fluid d-block mx-auto mb-5"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container separation">
            <div className="row mt-5 mb-5 align-items-center">
              <div className="col-md">
                <img
                  src="images/man-in-red-40cf348f8cfb64827da9c2e8e843e59b.jpg"
                  className="img-fluid d-block mx-auto"
                  alt="woman"
                />
              </div>
              <div className="col-md">
                <div className="main-content">
                  <div className="introduction">
                    {/* <h2>How Much Do Veneer Cost?</h2> */}
                    <h3 className="price">
                    Veneers As Low As $998
                      <span className="price-span"> per Veneer</span>
                    </h3>
                    <p className="payment-options mb-5 d-none">
                      <span className="option-1">
                        Includes 8 Veneers and a Nightguard
                        <sup className="d-none">*</sup>
                      </span>
                    </p>

                    <div className="d-none">
                      <h4 className="mt-4">Payment Plans Available</h4>
                      <p className="payment-options mb-5">
                        <span className="option-1">
                          As low as $135 per month for 60 months<sup>*</sup>
                        </span>
                        <br />
                        <span className="option-2">
                          As low as $335 per month for 24 months<sup>*</sup>
                        </span>
                        <br />
                        <span>
                          <small>*For those who qualify</small>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  };

  let view = getContent();
  return <FrontLayout view={view} />;
};
export default Expect;
