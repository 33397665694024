const isEmail = (email) => {
    let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
};

const validatePhone = (p) => {
    var phoneRe = /^[1-9]\d{2}[1-9]\d{2}\d{4}$/;

    var digits = p.replace(/\D/g, "");

    return phoneRe.test(digits);
};

export {isEmail,validatePhone};