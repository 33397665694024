import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import FrontLayout from '../layout/front';
import axios from "axios";
import { useState,useEffect } from "react";

const Home = () => {

  const [seoData, setSeoData] = useState([]);

  const apiSeoUrl = "https://www.admin.veneersmilesclinic.com/api/get-seo-data";

  const fetchSeoData = async () => {
    try {
      const response = await axios.get(`${apiSeoUrl}/151`);
    	setSeoData(response.data.seo_data);
    } catch (error) {
      console.error('Error fetching blog detail:', error);
    }
  };

	// Call fetchData on component mount
	useEffect(() => {
		fetchSeoData();
	}, []);

  const getContent = () => {
    return (
      <>
        <Helmet>
					<title>
						{seoData.meta_title}
					</title>
					<meta
						name="description"
						content={seoData.meta_description}
					></meta>

					<meta
						name="keywords"
						content={seoData.meta_keywords}
					></meta>
					<link rel="canonical" href={seoData.canonical_url}></link>
				</Helmet>
        
        <main>
          <div className="container-fluid h-100">
            <div className="row image-container">
              <div className="col-md"></div>
              <div className="col-md my-auto">
                <div className="main-cta">
                  <h2 className="main-title">
                   Smile with Veneer Smiles <sup>®</sup>
                  </h2>
                  <p>
                    Discover why Lumineers <sup>®</sup> are the #1
                    patient-requested veneer system in the world.
                  </p>
                  <p><strong className="sub-title">Veneers as low as $998<small> per Veneer</small></strong></p>
                  <div>
                    <Link className="main-cta-button" to="/what-to-expect">
                      Learn More
                    </Link>
                  </div>
                  <div className="btn-area">
                    Call: <a className="nav-link phonenum" href="tel:4047817978">(404) 781-7978</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="main-content">
                  <div className="introduction">
                    <h1 className="mt-5">Veneer Smiles A Lumineers Clinic</h1>
                    <p>
                      At Veneer Smiles located in Atlanta, Georgia, we
                      specialize in transforming smiles with Veneers, the #1
                      patient-requested veneer system in the world. Our
                      specially trained Certified Lumineers Dentists utilize
                      minimally invasive techniques to deliver beautiful smiles
                      without the need for painful shots or grinding down your
                      natural teeth, which often occurs with traditional veneer
                      placement. While many dentists offer veneers, not all
                      dentists are trained specifically to deliver ultra-thin
                      Lumineers. Schedule a consultation and start smiling with
                      Veneers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container separation">
            <div className="row mt-5 mb-5 align-items-center">
              <div className="col-md">
                <img
                  src="images/woman-in-city-cfee94e4a042831c0876ff91d82bfdea.jpg"
                  className="img-fluid d-block mx-auto"
                  alt="woman"
                />
              </div>
              <div className="col-md">
                <div className="main-content">
                  <div className="introduction">
                    {/* <h2>How Much Do Veneer Cost?</h2> */}
                    <h3 className="price">
                      <span className="price-txt">Veneers As Low As $998</span>
                      <span className="price-span"> per Veneer</span>
                    </h3>
                    <p className="payment-options mb-5 d-none">
                      <span className="option-1">
                        Includes 8 Veneers and a Nightguard
                        <sup className="d-none">*</sup>
                      </span>
                    </p>

                    <div className="d-none">

                    <h4 className="mt-4">Payment Plans Available</h4>
                    <p className="payment-options mb-5">
                      <span className="option-1">
                        As low as $135 per month for 60 months<sup>*</sup>
                      </span>
                      <br />
                      <span className="option-2">
                        As low as $335 per month for 24 months<sup>*</sup>
                      </span>
                      <br />
                      <span>
                        <small>*For those who qualify</small>
                      </span>

                    </p>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  };

  let view = getContent();
  return <FrontLayout view={view} />;
};

export default Home;
