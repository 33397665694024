import React from "react";
import { Helmet } from "react-helmet";
import FrontLayout from "../layout/front";
import { useState,useEffect } from "react";
import axios from "axios";

const Privacy = () => {

  const [seoData, setSeoData] = useState([]);

  const apiSeoUrl = "https://www.admin.veneersmilesclinic.com/api/get-seo-data";

  const fetchSeoData = async () => {
    try {
      const response = await axios.get(`${apiSeoUrl}/285`);
    	setSeoData(response.data.seo_data);
    } catch (error) {
      console.error('Error fetching blog detail:', error);
    }
  };

	// Call fetchData on component mount
	useEffect(() => {
		fetchSeoData();
	}, []);
  const getContent = () => {
    return (
      <>
        <Helmet>
					<title>
						{seoData.meta_title}
					</title>
					<meta
						name="description"
						content={seoData.meta_description}
					></meta>

					<meta
						name="keywords"
						content={seoData.meta_keywords}
					></meta>
					<link rel="canonical" href={seoData.canonical_url}></link>
				</Helmet>
        <main>
          <div className="privacy-container container mt-5 mb-5">
            <div className="row">
              <div className="col">
                <h1 className="">Privacy Policy</h1>
                <p>
                  This privacy policy has been compiled to better serve those
                  who are concerned with how their 'Personally Identifiable
                  Information' (PII) is being used online. PII, as described in
                  US privacy law and information security, is information that
                  can be used on its own or with other information to identify,
                  contact, or locate a single person, or to identify an
                  individual in context. Please read our privacy policy
                  carefully to get a clear understanding of how we collect, use,
                  protect or otherwise handle your Personally Identifiable
                  Information in accordance with our website.
                </p>
                <h3>
                  What personal information do we collect from the people that
                  visit our website?
                </h3>
                <p>
                  When scheduling a consultation or creating an account on our
                  site, as appropriate, you may be asked to enter your name,
                  email address, phone number, or other details to help you with
                  your experience.
                </p>
                <h3>When do we collect information?</h3>
                <p>
                  We collect information from you when you fill out a form or
                  enter information on our site.
                </p>
                <h3>How do we use your information?</h3>
                <p>
                  We may use the information we collect from you when you
                  schedule a consultation, create an account, sign up for our
                  newsletter, respond to a survey or marketing communication,
                  surf the website, or use certain other site features in the
                  following ways:
                </p>
                <ul>
                  <li>
                    To allow us to better service you in responding to your
                    customer service requests.
                  </li>
                  <li>
                    To send periodic emails regarding your appointments or other
                    products and services.
                  </li>
                  <li>
                    To follow up with them after correspondence (email or phone
                    inquiries)
                  </li>
                </ul>
                <h3>How do we protect your information?</h3>
                <ul>
                  <li>
                    We do not use vulnerability scanning and/or scanning to PCI
                    standards.
                  </li>
                  <li>We do not use Malware Scanning.</li>
                  <li>We do not use an SSL certificate.</li>
                  <li>
                    We only provide information. We never ask for credit card
                    numbers.
                  </li>
                </ul>
                <h3>Do we use 'cookies'?</h3>
                <p>
                  Yes. Cookies are small files that a site or its service
                  provider transfers to your computer's hard drive through your
                  Web browser (if you allow) that enables the site's or service
                  provider's systems to recognize your browser and capture and
                  remember certain information. They are used to help us
                  understand your preferences based on previous or current site
                  activity, which enables us to provide you with improved
                  services. We also use cookies to help us compile aggregate
                  data about site traffic and site interaction so that we can
                  offer better site experiences and tools in the future. We may
                  also use trusted third-party services that track this
                  information on our behalf.
                </p>
                <p>
                  You can choose to have your computer warn you each time a
                  cookie is being sent, or you can choose to turn off all
                  cookies. You do this through your browser settings. Since each
                  browser is a little different, look at your browser's Help
                  Menu to learn the correct way to modify your cookies. If you
                  turn cookies off, some of the features that make your site
                  experience more efficient may not function properly.
                </p>
                <h3>Third-party disclosure</h3>
                <p>
                  We do not sell, trade, or otherwise transfer to outside
                  parties your Personally Identifiable Information.
                </p>
                <h3>Third-party links</h3>
                <p>
                  We do not include or offer third-party products or services on
                  our website.
                </p>
                <h3>
                  We have implemented the following Google Analytics
                  functionality:
                </h3>
                <p>
                  We, along with third-party vendors such as Google use
                  first-party cookies (such as the Google Analytics cookies) and
                  third-party cookies or other third-party identifiers together.
                  We use them to compile data regarding user interactions and
                  service functions as they relate to our website.
                </p>
                <h3>Opting out:</h3>
                <p>
                  Users can opt out by using the Google Analytics Opt Out
                  Browser add on.
                </p>
                <h3>California Online Privacy Protection Act</h3>
                <p>
                  CalOPPA is the first state law in the nation to require
                  commercial websites and online services to post a privacy
                  policy. The law's reach stretches well beyond California to
                  require any person or company in the United States (and
                  conceivably the world) that operates websites collecting
                  Personally Identifiable Information from California consumers
                  to post a conspicuous privacy policy on its website stating
                  exactly the information being collected and those individuals
                  or companies with whom it is being shared.
                </p>
                <h3>According to CalOPPA, we agree to the following:</h3>
                <ul>
                  <li>Users can visit our site anonymously.</li>
                  <li>
                    Once this privacy policy is created, we will add a link to
                    it on our home page or as a minimum, on the first
                    significant page after entering our website.
                  </li>
                  <li>
                    Our Privacy Policy link includes the word 'Privacy' and can
                    easily be found on the page specified above.
                  </li>
                </ul>
                <h3>You will be notified of any Privacy Policy changes:</h3>
                <ul>
                  <li>On our Privacy Policy Page</li>
                </ul>
                <h3>Can change your personal information:</h3>
                <ul>
                  <li>Call (404) 697-5613.</li>
                </ul>
                <h3>How does our site handle Do Not Track signals?</h3>
                <p>
                  We don't honor Do Not Track signals and Do Not Track, plant
                  cookies, or use advertising when a Do Not Track (DNT) browser
                  mechanism is in place. We don't honor them because a Do Not
                  Track (DNT) standard has not been adopted to this day, and
                  therefore Veneer Smiles doesn't use DNT signals.
                </p>
                <h3>COPPA (Children Online Privacy Protection Act)</h3>
                <p>
                  When it comes to the collection of personal information from
                  children under the age of 13 years old, the Children's Online
                  Privacy Protection Act (COPPA) puts parents in control. The
                  Federal Trade Commission, United States' consumer protection
                  agency, enforces the COPPA Rule, which spells out what
                  operators of websites and online services must do to protect
                  children's privacy and safety online.
                </p>
                <p>
                  We do not specifically market to children under the age of 13
                  years old.
                </p>
                <h3>Fair Information Practices</h3>
                <p>
                  The Fair Information Practices Principles form the backbone of
                  privacy law in the United States and the concepts they include
                  have played a significant role in the development of data
                  protection laws around the globe. Understanding the Fair
                  Information Practice Principles and how they should be
                  implemented is critical to comply with the various privacy
                  laws that protect personal information. In order to be in line
                  with Fair Information Practices we will notify you via email
                  within 7 business days should a data breach occur.
                </p>
                <p>
                  We also agree to the Individual Redress Principle which
                  requires that individuals have the right to legally pursue
                  enforceable rights against data collectors and processors who
                  fail to adhere to the law. This principle requires not only
                  that individuals have enforceable rights against data users,
                  but also that individuals have recourse to courts or
                  government agencies to investigate and/or prosecute
                  non-compliance by data processors.
                </p>
                <h3>CAN SPAM Act</h3>
                <p>
                  The CAN-SPAM Act is a law that sets the rules for commercial
                  email, establishes requirements for commercial messages, gives
                  recipients the right to have emails stopped from being sent to
                  them, and spells out tough penalties for violations.
                </p>
                <h3>We collect your email address in order to:</h3>
                <ul>
                  <li>
                    Send information, respond to inquiries, and/or other
                    requests or questions.
                  </li>
                  <li>
                    Process requests and to send information and updates
                    pertaining to orders.
                  </li>
                  <li>
                    Send you additional information related to your product
                    and/or service.
                  </li>
                  <li>
                    Market to our mailing list or continue to send emails to our
                    clients after the original transaction has occurred.
                  </li>
                </ul>
                <h3>
                  To be in accordance with CANSPAM, we agree to the following:
                </h3>
                <ul>
                  <li>
                    Not use false or misleading subjects or email addresses.
                  </li>
                  <li>
                    Identify the message as an advertisement in some reasonable
                    way.
                  </li>
                  <li>
                    Include the physical address of our business or site
                    headquarters.
                  </li>
                  <li>
                    Monitor third-party email marketing services for compliance,
                    if one is used.
                  </li>
                  <li>Honor opt-out/unsubscribe requests quickly.</li>
                  <li>
                    Allow users to unsubscribe by using the link at the bottom
                    of each email.
                  </li>
                </ul>
                <h3>Contacting Us</h3>
                <p>
                  If there are any questions regarding this privacy policy, you
                  may contact us at (404) 697-5613
                </p>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  };

  let view = getContent();
  return <FrontLayout view={view} />;
};
export default Privacy;
