import React from 'react'
import './loader.css';

function Loader() {
	return (
		<>
			<div class="loading">
				<div class="loader"></div>
			</div>
		</>
	)
}

export default Loader;