import { Routes, Route, Link } from "react-router-dom";
import React, { Suspense } from "react";
import Home from "./Home/index.jsx";
import Privacy from "./Privacy/index.jsx";
import Expect from "./Expect/index";
import ContactUs from "./contact_us/index.jsx";
import UpLoad from "./upload_pic/UpLoad.jsx";
import BlogListing from "./blogListing/BlogListing.jsx";
import BlogDetail from "./blogDetail/BlogDetail.jsx";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/what-to-expect" element={<Expect />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="*" element={<Home />} />
        <Route path="/upload-pic/:link_code" element={<UpLoad />} />
        <Route path="/blog" element={<BlogListing />} />
        <Route path="/blog/:slug" element={<BlogDetail />} />
      </Routes>
    </>
  );
};

export default App;
