import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import FrontLayout from "../layout/front";
import Loader from "../loader/Loader";
import "./blogListing.css";

function BlogListing() {
  const baseURL = "https://www.admin.veneersmilesclinic.com/api/get-all-blogs";
  const blogImgURL =
    "https://www.admin.veneersmilesclinic.com/storage/uploads/blog";
  const backupImageUrl = "https://placehold.co/600x400";

  const [loading, setLoading] = useState(true);
  const [blogData, setBlogData] = useState([]);
  const [blogCatData, setBlogCatData] = useState([]);
  const [seoData, setSeoData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [statusCode, setStatusCode] = useState(null);
  const [showContent, setShowContent] = useState(false);

  // Fetching query parameters from URL
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  //const categoryParam = query.get('category');

  const fetchData = async () => {
    try {
      const response = await axios.get(baseURL);
      setBlogData(response.data.blog_data);
      setBlogCatData(response.data.blog_category);

      setLoading(false);
      setSeoData(response.data.seo_data);
      setStatusCode(response.status);
      setShowContent(response.status === 200);
    } catch (error) {
      console.error("Error fetching data:", error);
      setShowContent(false);
    }
  };

  // Call fetchData on component mount
  useEffect(() => {
    fetchData();
    //setSelectedCategory(categoryParam);
  }, []);

  const handleCategorySelect = (categoryID) => {
    setSelectedCategory(categoryID);
    if (window.sessionStorage.getItem("cat_active_id")) {
      window.sessionStorage.removeItem("cat_active_id");
    }
  };

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}?category=${selectedCategory}`
        );
        setBlogData(response.data.blog_data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching category data:", error);
      }
    };
    if (window.sessionStorage.getItem("cat_active_id")) {
      setSelectedCategory(window.sessionStorage.getItem("cat_active_id"));
    }

    if (selectedCategory !== null) {
      fetchCategoryData();
    } else {
      fetchData();
    }
  }, []);

  const getContent = () => {
    let displayedBlogs = blogData;

    if (selectedCategory !== null) {
      displayedBlogs = blogData.filter((blog) =>
        blog.blog.cate_ids.includes(selectedCategory.toString())
      );
    }

    return (
      <>
        <Helmet>
          <title>{seoData.meta_title}</title>
          <meta name="description" content={seoData.meta_description}></meta>
          <meta name="keywords" content={seoData.meta_keywords}></meta>
          <link rel="canonical" href={seoData.canonical_url}></link>
        </Helmet>
        <div className="blog-area">
          <div className="blog-heading">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  {statusCode === 404 ? (
                    <strong className="page-title">404 - Blog Not Found</strong>
                  ) : (
                    showContent && <h1 className="page-title">Blog </h1>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="blog-listing">
            <div className="container">
              {loading ? (
                <Loader />
              ) : (
                <div className="row">
                  <div className="col-md-3">
                    <div className="widget sticky">
                      <strong className="title">Categories</strong>
                      <ul>
                        <li
                          onClick={() => handleCategorySelect(null)}
                          className={selectedCategory === null ? "active" : ""}
                        >
                          <Link to="#">All</Link>
                        </li>
                        {blogCatData.map((category, index) => (
                          <li
                            key={index}
                            onClick={() => handleCategorySelect(category.ID)}
                            className={
                              selectedCategory === category.ID ||
                              category.ID.toString() === selectedCategory
                                ? "active"
                                : ""
                            }
                          >
                            <Link to={`?category=${category.ID}`}>
                              {category.cate_title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                    
                  {displayedBlogs.length > 0 ? (
                    <div className="col-md-9 position-relative">
                      <div className="row">
                        {displayedBlogs.map((singleBlog, index) => (
                          <div className="col-md-6" key={index}>
                            <div className="post">
                              <div className="img">
                                <Link
                                  to={`/blog/${encodeURIComponent(
                                    singleBlog.blog.post_slug
                                  )}`}
                                >
                                  <img
                                    src={`${blogImgURL}/${singleBlog.blog.featured_img}`}
                                    alt={`${singleBlog.blog.featured_img_alt}`}
                                  />
                                </Link>
                              </div>
                              <div className="text">
                                <h2>
                                  <Link
                                    to={`/blog/${encodeURIComponent(
                                      singleBlog.blog.post_slug
                                    )}`}
                                  >
                                    {singleBlog.blog.title}
                                  </Link>
                                </h2>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      singleBlog.blog.description.slice(
                                        0,
                                        150
                                      ) +
                                      (singleBlog.blog.description.length > 150
                                        ? "..."
                                        : ""),
                                  }}
                                />
                                <div className="btn-area">
                                  <Link
                                    to={`/blog/${encodeURIComponent(
                                      singleBlog.blog.post_slug
                                    )}`}
                                    className="btn btn-primary"
                                  >
                                    Read More
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                   
                        <div className="col-md-9 position-relative">
                          <h1>No Record Found</h1>
                        </div>
                   
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  let view = getContent();
  return <FrontLayout view={view} />;
}

export default BlogListing;
