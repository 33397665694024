

import Header from "../common_view/Header";
import Footer from "../common_view/Footer";

const FrontLayout = (props) => {
  return (
    <>
      <Header />
      {props.view}
      <Footer />
    </>
  );
};

export default FrontLayout;
